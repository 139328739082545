import { API } from 'api';
import getRoute from 'api/apiRoutes';

class AppApi extends API {
  getReport = (question: string): Promise<any> => {
    const route = getRoute('getQuestionResult');
    return this.postRequest(route, { question });
  };
}

export default new AppApi();
