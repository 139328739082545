const questions = [
  'What is COMPANY_NAME?',
  'When was COMPANY_NAME founded?',
  'What does COMPANY_NAME do?',
  'What products or services does COMPANY_NAME offer?',
  "Are COMPANY_NAME's products good?",
  "Are COMPANY_NAME's products bad?",
  'Tell me something interesting about COMPANY_NAME?',
  'How much sales does COMPANY_NAME do every year?',
  'How much profit does COMPANY_NAME make?',
  'How many employees does COMPANY_NAME have?',
  'What is the value of COMPANY_NAME?',
  "What is the value of COMPANY_NAME's assets?",
  'Is COMPANY_NAME a good company?',
  'Why? Is COMPANY_NAME a bad company?',
  'What is in COMPANY_NAME’s future?',
  'How big is COMPANY_NAME?',
  'WHo owns COMPANY_NAME?',
  'Who are COMPANY_NAMEs shareholders?',
  'Who is the CEO of COMPANY_NAME?',
  'WHo is the CFO of COMPANY_NAME?',
  "Who are COMPANY_NAME's competitors?",
  'Who are COMPANY_NAMEs customers?',
  "Who is COMPANY_NAME's biggest customer?",
  'Is COMPANY_NAME a good investment?',
  'Is COMPANY_NAME growing?',
  'Does COMPANY_NAME have a lot of cash?',
  'Who are COMPANY_NAME’s biggest suppliers?',
  'Does COMPANY_NAME have any supply chain problems?',
  'Who are COMPANY_NAME’s biggest channel partners?',
  "What is COMPANY_NAME's price/earnings ratio?",
  'What are COMPANY_NAMEs debts?',
  'Is anyone suing COMPANY_NAME?',
  'Does COMPANY_NAME have any judgments against it?',
  'What is COMPANY_NAME’s business plan'
];

export const getQuestions = (company: string) => {
  return questions.map(ques => ques.replace(/COMPANY_NAME/g, company));
};
