import { API_TYPE } from 'utils';

export interface IAction {
  type: string;
  payload?: any;
}

/* -------------------------------------------------------------------------- */
/*                                     APP                                    */
/* -------------------------------------------------------------------------- */
export const SET_REDUX_KEY = 'SET_REDUX_KEY';
export const CLEAR_REDUX = 'CLEAR_REDUX';

/* --------------------------------- LOADER --------------------------------- */
export const APP = API_TYPE('APP');
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const GET_REPORT = API_TYPE('GET_REPORT');
