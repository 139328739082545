import { CLEAR_REDUX, SET_REDUX_KEY } from 'state/types';
import { IAction } from 'state/types';

function reducer(state = { activeTab: 'query' }, action: IAction) {
  switch (action.type) {
    case SET_REDUX_KEY:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    case CLEAR_REDUX:
      return {
        ...state
      };
    default:
  }
  return state;
}

export default reducer;
