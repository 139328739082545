/* eslint-disable no-constant-condition */
import { all } from 'redux-saga/effects';
import { app } from 'state/sagas/app';
// import { auth } from 'state/sagas/auth';
// import { news } from 'state/sagas/news';
// import { company } from 'state/sagas/company';
// import { user } from 'state/sagas/user';

export default function* rootSaga() {
  const sagas = [...app];
  yield all(sagas);
}
